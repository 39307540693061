<template>
  <Authenticated>
    <Warning :show="showDeleteConfirm" message="Confirm you want to delete this modifier set?" primary="Delete" @save="removeSet" />
    <ModifierModal :show="showModifierModal" :modifier="currentModifier" @save="saveModifier" @remove="removeModifier"  />
    <div v-if="current" class="fab-container phone-buttons">
      <div :class="(optionsDisplay ? 'button-active' : '') + ' button iconbutton action-buttons'" @click="optionsDisplay = !optionsDisplay">
        <div>
          <font-awesome-icon icon="gear" :class="(optionsDisplay ? 'gear-icon-active' : '') + ' gear-icon'"></font-awesome-icon>
        </div>
      </div>
      <ul :class="(optionsDisplay ? 'options-disp' : '') + ' options'">
        <li class="bars-rule">
          <Toggle class="toggle" onLabel="Track Inventory" offLabel="Track Inventory" v-model="current.rules.inventory" />
        </li>
        <li class="bars-rule">
          <Toggle class="toggle" onLabel="Limit One" offLabel="Limit One" v-model="current.rules.oneonly" />
        </li>
        <li class="bars-rule">
          <Toggle class="toggle" onLabel="Required" offLabel="Required" v-model="current.rules.required" />
        </li>
      </ul>
    </div>
    <div v-if="current">
      <div class="actions">
      <font-awesome-icon class="backward" icon="backward" @click.stop.prevent="$router.go(-1)"></font-awesome-icon>
      <h2 class="title">{{ name }}</h2></div>
      <div class="buttons" v-if="current.rules">
        <div class="desktop-buttons">
          <span class="option">
            <Toggle class="toggle" onLabel="Track Inventory" offLabel="Track Inventory" v-model="current.rules.inventory" />
          </span>
          <span class="option">
            <Toggle class="toggle" onLabel="Limit One" offLabel="Limit One" v-model="current.rules.oneonly" />
          </span>
          <span class="option">
            <Toggle class="toggle" onLabel="Required" offLabel="Required" v-model="current.rules.required" />
          </span>
          <button @click="confirmDelete">Delete Modifier Set</button>
          <button v-if="current.rules.inventory"
            @click="
              this.modifiers.forEach((mod) => {
                mod.quantity = 0;
              }); this.save();
            ">
            Zero Out Quantity
          </button>
        </div>
      </div>

      <ul class="table wrapper" ref="history">
        <li v-bind:key="idx" v-for="(modifier, idx) in modifiers" class="modifierBox" @click.stop.prevent="showModifier(modifier)">
          <div class="box">
            <p style="text-align: center">
              <span class="modifier-name">{{ modifier.name }}</span>
              <span class="modifier-price">{{ "$" + (modifier.price ? (+modifier.price).toFixed(2) : "0.00") }}</span>
              <span class="modifier-quantity" v-if="current.rules.inventory">
                <h3>{{ modifier.quantity }} units</h3>
              </span>
              <span v-if="current.rules.inventory" @click.stop.prevent="updateModifierQuantity(modifier)"
                >Quantity: <input class="text_input" type="text" v-model="modifier.quantity" @input="updateModifierQuantity(modifier)"
              /></span>
              <span v-if="current.rules.inventory" class="inventory-control" @click.stop.prevent="updateModifierQuantity(modifier)">
                <!-- <input
                  type="range"
                  v-model="modifier.quantity"
                  min="0"
                  max="100"
                  @change="updateModifierQuantity(modifier)"
                /> -->
              </span>
            </p>
          </div>
        </li>

        <li @click="addModifier" class="modifierBox newModifier">
          <div class="box">
            <p style="text-align: center">
              <span class="modifier-icon"><font-awesome-icon icon="plus" /></span>
              <span class="modifier-name">Add Modifier</span>
            </p>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="current">
      <h3>Products using Modifier</h3>
      <ul class="table wrapper" ref="history">
        <template v-for="product in modifierset_in_product.find((modprod) => modprod.mod == current._id).products" :key="product">
          <li v-if="productCat(product._id)" class="modifierBox">
            <router-link class="modifier-name" :to="`/products/${productCat(product._id).id.split(':')[1]}/${product._id.split(':')[1]}`"
              >{{ product.name }} <br />
              {{ productCat(product._id).id.split(":")[1] }}</router-link
            >
          </li>
        </template>
      </ul>
    </div>
  </Authenticated>
</template>

<script>
import Authenticated from "@/components/_layouts/Authenticated";
import Warning from "@/components/_layouts/Warning";

import { mapGetters } from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import Toggle from "@vueform/toggle";
import ModifierModal from "@/components/Modifiers/ModifierModal";

export default {
  name: "ModifierSet",
  mixins: [createdMixin],
  components: {
    Authenticated,
    Warning,
    Toggle,
    ModifierModal,
  },

  data() {
    return {
      channel: null,
      currentSet: null,
      currentModifier: null,
      currentDrag: null,
      currentDragOverCategory: null,
      showDeleteConfirm: false,
      inventory: false,
      required: false,
      oneonly: false,
      loaded: false,
      showModifierModal: false,
      tableMode: true,
      optionsDisplay: false,
    };
  },

  methods: {
    confirmDelete() {
      this.showDeleteConfirm = !this.showDeleteConfirm;
    },
    save() {
      this.channel.push("document:update", this.current);
    },
    showModifier(modifier) {
      this.currentModifier = modifier;
      this.showModifierModal = !this.showModifierModal;
    },
    addModifier() {
      this.currentModifier = { name: null, price: 0, quantity: 0, desc: "", active: false };
      this.showModifierModal = !this.showModifierModal;
    },
    saveModifier(modifier) {
      var foundMod = this.current.modifiers.find((mod) => modifier.name === mod.name);
      if (foundMod) {
        foundMod.name = modifier.name;
        foundMod.price = modifier.price;
        foundMod.quantity = modifier.quantity;
        this.save();
      } else {
        this.current.modifiers.push(modifier);
        this.save();
      }
    },
    updateModifierQuantity(modifier) {
      //console.log(
      //   "Update quantity for " + modifier + " to " + modifier.quantity
      // );
      if (Number.isInteger(parseInt(modifier.quantity))) {
        this.channel.push("modifier:set:quantity", {
          modifierset: this.fullslug,
          modifier: modifier.name,
          quantity: modifier.quantity,
        });
      }
    },
    removeModifier(modifier) {
      var foundModIndex = this.current.modifiers.findIndex((mod) => modifier.name === mod.name);
      if (foundModIndex != -1) {
        this.current.modifiers.splice(foundModIndex, 1);
        this.save();
      }
    },
    async removeSet() {
      /* step two remove it from modifier_sets */
      await this.channel.push("document:delete", {
        doc: this.current._id,
        rev: this.current._rev,
      });
      this.$router.push("/modifiers");
    },
  },

  watch: {
    "current.rules.oneonly": {
      handler: function (newValue, oldValue) {
        if (oldValue != null && newValue != undefined) this.save();
      },
    },
    "current.rules.required": {
      handler: function (newValue, oldValue) {
        if (oldValue != null && newValue != undefined) this.save();
      },
    },
    "current.rules.inventory": {
      handler: function (newValue, oldValue) {
        if (oldValue != null && newValue != undefined) this.save();
      },
    },
  },
  mounted() {
    this.$store.commit("currPage", "modifiers");
  },
  computed: {
    getCategory(cat) {
      return this.productCat("category:" + cat);
    },
    slug() {
      return this.$route.params.set;
    },
    fullslug() {
      return "modifierset:" + this.$route.params.set;
    },
    name() {
      return this.$route.params.set.replace(/-/g, " ");
    },
    current() {
      let foundSet = this.modifier_sets.find((set) => set.id === "modifierset:" + this.slug);
      if (foundSet && "rules" in foundSet.value && Array.isArray(foundSet.value.rules)) {
        foundSet.value["rules"] = {
          inventory: false,
          oneonly: false,
          required: false,
        };
      }
      if (foundSet && !("rules" in foundSet.value)) {
        foundSet.value["rules"] = {
          inventory: false,
          oneonly: false,
          required: false,
        };
      }
      return foundSet ? foundSet.value : null;
    },
    modifiers() {
      return this.current ? this.current.modifiers : null;
    },
    ...mapGetters({
      modifier_sets: "modifier_sets",
      allProducts: "allProducts",
      modifierset_in_product: "modifierset_in_product",
      productCat: "productCat",
    }),
  },
};
</script>
<style src="@vueform/toggle/themes/default.css"></style>

<style scoped lang="scss">
@import "public/layout";
@import "public/wrapper";
@import "public/actions";

.toggle {
  --toggle-width: 12rem;
  --toggle-border-off: black;
  --toggle-border-on: black;
  --toggle-bg-on: var(--action-colour);
  --toggle-border: 2px;
  --toggle-height: 48px;
  --toggle-font-size: 1rem;
  outline-color: green;
  outline: 0;
  border-color: var(--action-colour);
  border-left-width: 1px;
  border-right-width: 1px;
}

.modifier-quantity {
  display: block;
}

.modifierBox {
  margin: 10px;
  a {
    text-decoration: none;
    color: var(--scondary-colour);
  }
}

.box {
  vertical-align: center;
}

.box p {
  flex: 0 1;
  min-width: 150px;
  margin: 0px;
}

.box .inventory-control {
  //transform: translate(0%, 250%);
  flex: 1 1;

  input[type="range"] {
    width: 100%;
    background-color: #ccc;
  }
}

.box .text_input {
  width: 50%;
  height: 2rem;
  font-size: 1.5rem;
  text-align: center;
}

.fab-container {
  position: fixed;
  bottom: 25px;
  right: 25px;
  cursor: pointer;
  z-index: 100;
}

.iconbutton {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  //box-shadow: 10px 10px 5px #aaaaaa;
}

.button {
  width: 60px;
  height: 60px;
  background: var(--secondary-colour);
  border: 0.25rem solid var(--banner-colour);
}

.button-active {
  background: var(--action-colour);
}

.iconbutton div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  color: white;
}

.options {
  display: none;
  list-style-type: none;
  position: absolute;
  bottom: 70px;
  right: 0;
}

.options li {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
}

.gear-icon {
  height: 1.5rem;
  color: var(--action-colour);
}

.gear-icon-active {
  color: var(--secondary-colour);
}

.options-disp {
  display: block;
}



@media only screen and (orientation: portrait) {
  .box .inventory-control {
    input[type="range"] {
      width: 80%;
    }
  }
}
</style>
